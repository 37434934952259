import * as React from "react";

function WaterGlass(props) {
  const { fill, ...restProps } = props
  return (
    <svg width={24} height={24} fill={fill} viewBox="0 0 24 24" className="dashboard-svg" {...restProps}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.44 1.72c-.24-.27-.58-.42-.93-.42H4.49c-.36 0-.69.15-.93.42-.24.26-.35.61-.31.97l2.07 18.9c.07.63.6 1.11 1.24 1.11h10.87c.64 0 1.17-.48 1.24-1.11l2.07-18.9c.05-.36-.06-.71-.3-.97zM7.68 20.2h8.64l1.8-16.4H5.89l1.79 16.4z"
      />
      <path className="dashboard-water-glass-line-path"
        d="M9.73 7.32h8l-.28 2.5H9.73a1.25 1.25 0 010-2.5z"
      />
    </svg>
  );
}

// Set default props
WaterGlass.defaultProps = {
  fill: "#55698B",
};

export default WaterGlass;