import * as React from "react";

function MoreOptions(props) {
  const { fill, ...restProps } = props
  return (
    <svg width={5} height={20} fill={fill} viewBox="0 0 5 20" className="dashboard-svg more-options-dots" {...restProps}>
      <g>
        <path className="dot2" d="M4.5 10C4.5 11.1039 3.60446 11.9995 2.50053 11.9995C1.39554 11.9995 0.5 11.1039 0.5 10C0.5 8.89502 1.39554 8.00055 2.50053 8.00055C3.60446 8.00055 4.5 8.89502 4.5 10Z" />
        <path className="dot1" d="M4.5 17.2337C4.5 18.3387 3.60446 19.2332 2.50053 19.2332C1.39554 19.2332 0.5 18.3387 0.5 17.2337C0.5 16.1298 1.39554 15.2343 2.50053 15.2343C3.60446 15.2343 4.5 16.1288 4.5 17.2337Z" />
        <path className="dot3" d="M4.5 2.76622C4.5 3.87122 3.60446 4.76675 2.50053 4.76675C1.39554 4.76675 0.5 3.87122 0.5 2.76622C0.5 1.66229 1.39554 0.766754 2.50053 0.766754C3.60446 0.766754 4.5 1.66229 4.5 2.76622Z" />
      </g>
    </svg>
  );
}

// Set default props
MoreOptions.defaultProps = {
  fill: "#81D3F0",
};

export default MoreOptions;