import * as React from "react";

function Snowflake(props) {
  const {fill, ...otherProps} = props
  return (
    <svg width={24} height={24} fill={fill} viewBox="0 0 24 24" className="dashboard-svg" {...otherProps}>
      <path className="dashboard-snowflake-path"
        d="M19.2 14.72L14.5 12l4.7-2.72c.6-.35.8-1.11.46-1.71-.35-.6-1.11-.8-1.71-.46l-4.7 2.72V4.4a1.25 1.25 0 00-2.5 0v5.43l-4.7-2.72c-.6-.34-1.36-.14-1.71.46-.35.6-.14 1.37.46 1.71L9.5 12l-4.7 2.72c-.6.35-.8 1.11-.46 1.71.23.4.65.62 1.08.62.21 0 .43-.05.62-.17l4.7-2.72v5.43a1.25 1.25 0 002.5 0v-5.43l4.7 2.72c.2.11.41.17.62.17.43 0 .85-.22 1.08-.62.37-.6.16-1.37-.44-1.71z"
      />
    </svg>
  );
}

// Set default props
Snowflake.defaultProps = {
  fill: "#55698B",
};

export default Snowflake;