import * as React from "react";

function Arrow(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M46.97 17.731c.033-.052.053-.105.085-.157.072-.125.144-.243.203-.374.026-.072.046-.145.065-.217.04-.118.085-.236.112-.367a3.235 3.235 0 000-1.233c-.027-.125-.072-.243-.112-.361-.026-.072-.039-.151-.065-.223a2.46 2.46 0 00-.196-.368c-.033-.052-.052-.118-.092-.17a2.972 2.972 0 00-.386-.473L34.742 1.908a3.107 3.107 0 00-4.394 0 3.119 3.119 0 000 4.408l6.546 6.567H3.606A3.114 3.114 0 00.5 16a3.114 3.114 0 003.106 3.116h33.287l-6.546 6.567a3.119 3.119 0 000 4.409c.608.61 1.4.912 2.197.912.798 0 1.59-.302 2.197-.912l11.85-11.888c.144-.144.275-.301.386-.472h-.007z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Arrow;