import * as React from "react";

function Download(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 43 43"
        className="icon icon-svg"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.624 42.3H3.37a2.67 2.67 0 01-2.673-2.666v-7.306a2.67 2.67 0 012.673-2.666H14.6l4.097 4.086a3.979 3.979 0 005.6 0l4.097-4.086h11.23a2.67 2.67 0 012.673 2.666v7.306a2.67 2.67 0 01-2.673 2.666zm-8.914-5.197c-.874 0-1.584.708-1.584 1.58 0 .873.71 1.58 1.584 1.58.875 0 1.584-.707 1.584-1.58 0-.872-.709-1.58-1.584-1.58zm5.461 0c-.875 0-1.584.708-1.584 1.58 0 .873.709 1.58 1.584 1.58 1.289 0 1.584-.707 1.584-1.58 0-.872-.295-1.58-1.584-1.58zm-12.782-6.48a2.689 2.689 0 01-3.783 0L7.667 18.716c-1.04-1.038-.688-1.887.784-1.887h7.911V3.365c0-1.473 1.197-2.667 2.988-2.667h4.609a2.67 2.67 0 012.673 2.667v13.464h7.911c1.607 0 1.824.849.784 1.887L23.389 30.623z"
        fill="#fff"
      />
    </svg>
  );
}

export default Download;