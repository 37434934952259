import * as React from "react";

function Lightning(props) {
  const { fill, ...restProps } = props
  return (
    <svg width={24} height={24} fill={fill} viewBox="0 0 24 24" className="dashboard-svg" {...restProps}>
      <path className="dashboard-lightning-path"
        d="M16.08 11.51c-.21-.42-.64-.68-1.11-.68h-3.51l4.53-6.35a1.248 1.248 0 00-2.03-1.45l-5.93 8.32c-.27.38-.31.88-.09 1.3.22.42.64.68 1.11.68h3.51l-4.53 6.35c-.4.56-.27 1.34.29 1.74.22.16.47.23.72.23.39 0 .77-.18 1.02-.52L16 12.81c.26-.38.29-.88.08-1.3z"
      />
    </svg>
  );
}

// Set default props
Lightning.defaultProps = {
  fill: "#55698B",
};

export default Lightning;