import * as React from "react";

function RiverFlow(props) {
  const { fill, ...restProps } = props
  return (
    <svg width={24} height={24} fill={fill} viewBox="0 0 24 24" className="dashboard-svg" {...restProps}>
      <path className="dashboard-riverflows-path"
        d="M2.92 7.42a6.194 6.194 0 014.15-1.6c1.52 0 2.97.55 4.11 1.55l.05.05a8.466 8.466 0 005.7 2.19c2.11 0 4.14-.78 5.7-2.19.47-.42.51-1.15.08-1.62-.43-.47-1.16-.51-1.63-.08a6.194 6.194 0 01-4.15 1.6c-1.52 0-2.97-.55-4.11-1.55l-.05-.05a8.515 8.515 0 00-11.4 0c-.47.42-.51 1.15-.08 1.62.42.48 1.16.51 1.63.08zM2.92 12.85a6.194 6.194 0 014.15-1.6c1.52 0 2.97.55 4.11 1.56.01.01.03.03.05.04a8.466 8.466 0 005.7 2.19c2.11 0 4.14-.78 5.7-2.19a1.154 1.154 0 00-1.55-1.71 6.194 6.194 0 01-4.15 1.6c-1.52 0-2.98-.55-4.11-1.56a.218.218 0 00-.05-.04 8.466 8.466 0 00-5.7-2.19c-2.11 0-4.14.78-5.7 2.19-.47.43-.51 1.16-.08 1.63.42.48 1.16.51 1.63.08zM21.08 16.58a6.194 6.194 0 01-4.15 1.6c-1.52 0-2.98-.55-4.11-1.56a.218.218 0 00-.05-.04 8.466 8.466 0 00-5.7-2.19c-2.11 0-4.14.78-5.7 2.19-.47.42-.51 1.15-.08 1.62.43.47 1.16.51 1.63.08a6.194 6.194 0 014.15-1.6c1.52 0 2.97.55 4.11 1.56.01.01.03.03.05.04a8.466 8.466 0 005.7 2.19c2.11 0 4.14-.78 5.7-2.19.47-.43.51-1.16.08-1.63-.42-.47-1.16-.5-1.63-.07z"
      />
    </svg>
  );
}

// Set default props
RiverFlow.defaultProps = {
  fill: "#55698B",
};

export default RiverFlow;