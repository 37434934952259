import * as React from "react";

function RiverFlowDial(props) {
  return (
    <svg className="river-flow-dial" width={59} height={40} viewBox="0 0 59 40" fill="none" {...props}>
      <path
        d="M15.594 5.279A28.044 28.044 0 0129.684 1.5c5.135 0 9.945 1.38 14.091 3.779"
        stroke="#29ABE2"
        strokeWidth={3}
      />
      <path
        d="M43.774 5.279c8.423 4.874 14.094 13.974 14.094 24.405"
        stroke="#8BC440"
        strokeWidth={3}
      />
      <path
        d="M15.594 5.279C7.171 10.153 1.5 19.253 1.5 29.684"
        stroke="#fff"
        strokeWidth={3}
      />
      <path className="riverflow-gauge"
        d="M34.844 24.658l-5.159-13.584-5.16 13.584a5.636 5.636 0 1010.319 0zm-5.159 5.501a3.24 3.24 0 11-.002-6.478 3.24 3.24 0 01.002 6.478z"
        fill="#fff"
      />
    </svg>
  );
}

export default RiverFlowDial;