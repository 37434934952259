import * as React from "react";

function WaterDrop(props) {
  const { fill, ...restProps } = props
  return (
    <svg width={24} height={24} fill={fill} viewBox="0 0 24 24" className="dashboard-svg" {...restProps}>
      <path className="dashboard-water-drop-path"
        d="M18.04 12.59c-.02-.07-.05-.14-.08-.21l-4.84-9.71A1.26 1.26 0 0012 1.98c-.47 0-.91.27-1.12.69l-4.84 9.71c-.03.07-.06.13-.08.2-.42.9-.63 1.83-.63 2.77 0 3.67 2.99 6.66 6.66 6.66s6.66-2.99 6.66-6.66c.01-.94-.2-1.87-.61-2.76zM12 19.52c-2.3 0-4.16-1.87-4.16-4.16 0-.62.16-1.25.46-1.86.03-.06.06-.13.08-.19L12 6.03l3.62 7.27c.02.07.05.13.08.2.31.61.46 1.23.46 1.86 0 2.29-1.86 4.16-4.16 4.16z"
      />
    </svg>
  );
}

// Set default props
WaterDrop.defaultProps = {
  fill: "#55698B",
};

export default WaterDrop;