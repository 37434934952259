import * as React from "react";

function Boat(props) {
  const { fill, ...restProps } = props
  return (
    <svg width={24} height={24} fill={fill} viewBox="0 0 24 24" className="dashboard-svg" {...restProps}>
      <path className="dashboard-boat-path"
        d="M22.32 15.37c-.2-.45-.65-.74-1.14-.74h-3.72C17.18 4.86 10.3 1.82 10 1.7c-.38-.17-.83-.13-1.18.11-.34.23-.55.62-.55 1.04v11.78H2.8c-.46 0-.89.26-1.1.66-.21.4-.19.9.07 1.29l3.62 5.34c.23.34.62.55 1.04.55h9.93c.35 0 .69-.15.93-.41l4.82-5.34c.32-.37.41-.9.21-1.35zM10.77 5.13c1.7 1.4 4.03 4.28 4.2 9.5h-4.2v-9.5zm5.02 14.84H7.08l-1.92-2.84h13.2l-2.57 2.84z"
      />
    </svg>
  );
}

// Set default props
Boat.defaultProps = {
  fill: "#55698B",
};

export default Boat;