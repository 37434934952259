import React from 'react'
import { Link } from 'gatsby'
import TranslateIcon from './icons/TranslateIcon'


const Navbar = class extends React.Component {
  render() {
    return (
      <nav
        className="navbar navbar-featured is-home"
        role="navigation"
        id="featuredNavbar"
        aria-label="featured navigation"
      >
        <div className="container ml-0">

          <div
            id="navMenu"
            className={`navbar-menu is-block-mobile`}
          >
            <div className="navbar-start has-text-centered">

            </div>
            <div className="navbar-end has-text-right navbar-items has-text-weight-medium">
              <Link className="navbar-item" to="/news">
                Recent News
              </Link>
              <Link className="navbar-item" to="/#dashboard">
                Dashboard
              </Link>
              <Link className="navbar-item" to="/resources">
                Resources
              </Link>
              <Link className="navbar-item is-size-4 is-size-3-tablet" to="#translate">
                <TranslateIcon/>
              </Link>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default Navbar
