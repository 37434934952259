import * as React from "react";

function Close(props) {
  const { fill, ...restProps } = props
  return (
    <svg width={15} height={15} viewBox="0 0 15 15" fill={fill} className="dashboard-svg back-of-card-close" {...restProps}>
        <path
          d="M8.8 7l4.9-4.9c.5-.5.5-1.3 0-1.8s-1.3-.5-1.8 0L7 5.3 2.1.4C1.6-.1.8-.1.3.4s-.5 1.3 0 1.8l5 4.8-4.9 4.9c-.5.5-.5 1.3 0 1.8.2.2.6.4.9.4.3 0 .6-.1.9-.4L7 8.8l4.9 4.9c.2.2.6.4.9.4.3 0 .6-.1.9-.4.5-.5.5-1.3 0-1.8L8.8 7z"
        />
    </svg>
  );
}

// Set default props
Close.defaultProps = {
  fill: "#A3DDF3",
};

export default Close;