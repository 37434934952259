import { Link } from 'gatsby'
import React from 'react'
import Arrow from './icons/Arrow'

export default function Alert({ props }) {
    const { boldText, text, link, enabled } = props
    return (
        <>
            {enabled
                ? (link?.startsWith("/") && link?.startsWith("/static/")) ?
                    <Link to={link} className="is-block">
                        <AlertContent props={{ boldText, text }} />
                    </Link>
                    : link ? <a href={link} className="is-block" target='_blank' rel="noreferrer">
                        <AlertContent props={{ boldText, text }} />
                    </a>
                        : <div className="is-block">
                            <AlertContent props={{ boldText, text }} />
                        </div>
                : null}
        </>
    )
}

function AlertContent({ props }) {
    const { boldText, text } = props
    return (
        <div className="is-flex w-100 grow is-justify-content-center is-align-items-center px-4 py-2 has-text-white has-text-centered" style={{ background: "#363636" }}>
            <span className="has-text-weight-bold mr-1">{boldText}</span> {text} <Arrow className="ml-2" style={{ width: '1.5rem' }} />
        </div>
    )
}
